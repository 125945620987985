import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const LandDatabaseManagement = () => (
  <Layout>
    <SEO title="Land Database Management" />

    <div className="page-content">
      <div className="container">
        <h1 className="display-4 text-orange">Land Database Management</h1>
        <p>
          We are a trusted Irth Solutions Partner and as such we are thrilled to be
          able to offer Irth's Land Management solution
          to our clients.
        </p>
        <h2 className="my-4">Simplify Your Management Of Oil & Gas Leases</h2>
        <p>
        Irth's market-leading SaaS platform improves resilience and reduces risk in the sustainable delivery of essential services that millions of people and businesses rely on every day.
        </p>
        <p>
        Energy, utility, and telecom companies across the U.S. and Canada trust Irth for damage prevention, training, asset inspections, and land management solutions. Powered by business intelligence, analytics, and geospatial data, our platform helps deliver the 360-degree situational awareness needed to proactively mitigate and manage risk of critical network infrastructure in a changing environment. Irth has been the top provider for 811 (one call) ticket management and utility locating software since 1995.{" "}
        </p>
        <div className="text-center mt-5">
          <a
            className="btn-primary"
            href="https://www.irthsolutions.com/land-management/"
            target="_blank"
            rel="noreferrer"
          >
            Irth Solutions
          </a>
        </div>
      </div>
    </div>
  </Layout>
)

export default LandDatabaseManagement
